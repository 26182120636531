var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"login_page reset_password"},[_c('div',{staticClass:"page_container"},[_c('div',{staticClass:"login_inner"},[_c('div',{staticClass:"login_block"},[_c('div',{staticClass:"login_top"},[_c('Logo'),_c('LngSwitcher',{attrs:{"lngBlock":"login_lg"}})],1),_c('div',{staticClass:"login_form"},[_c('div',{staticClass:"form_title"},[_vm._v(_vm._s(_vm.$t("Recovery password")))]),_c('div',{staticClass:"form_description"},[_vm._v(" "+_vm._s(_vm.$t("Enter the phone number"))+" ")]),_c('ValidationObserver',{ref:"recovery_pass",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendCode)}}},[_c('ValidationProvider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"field_block",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"field_title"},[_vm._v(_vm._s(_vm.$t("Phone number")))]),_c('vue-tel-input',{attrs:{"type":"tel","name":"phone","autoFormat":"","custom-validate":/^[0-9 ]*$/},on:{"input":_vm.checkNumber,"validate":_vm.validatePhone}}),_c('span',{staticClass:"error_hint"},[_vm._v(_vm._s(errors[0]))]),_c('span',{staticClass:"icon_info check_input"})],1)]}}],null,true)}),_c('div',{staticClass:"info_block"},[_vm._v(" "+_vm._s(_vm.$t("An SMS notification will be sent to the phone number"))+" ")]),_c('button',{staticClass:"validate_btn"},[_vm._v("Ուղարկել կոդը")])],1)]}}])}),_c('div',{staticClass:"bottom_block"},[_c('router-link',{staticClass:"enter_btn",attrs:{"custom":"","to":"/login"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("Previous page"))+" ")])]}}])})],1)],1)]),_c('AppStore')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }